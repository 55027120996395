/* Fonts */
@font-face {
    font-family: 'Graphik Web';
    src: url('/fonts/Graphik-Regular-Web.eot') format('embedded-opentype'),
        url('/fonts/Graphik-Regular-Web.woff') format('woff'),
        url('/fonts/Graphik-Regular-Web.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik Web';
    src: url('/fonts/Graphik-Medium-Web.eot') format('embedded-opentype'),
        url('/fonts/Graphik-Medium-Web.woff') format('woff'),
        url('/fonts/Graphik-Medium-Web.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Graphik Web';
    src: url('/fonts/Graphik-Semibold-Web.eot') format('embedded-opentype'),
        url('/fonts/Graphik-Semibold-Web.woff') format('woff'),
        url('/fonts/Graphik-Semibold-Web.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-roman.var.woff2') format('woff2 supports variations'),
        url('/fonts/Inter-roman.var.woff2') format('woff2-variations');
    font-weight: 100 900;
    font-display: swap;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('/fonts/Inter-italic.var.woff2') format('woff2 supports variations'),
        url('/fonts/Inter-italic.var.woff2') format('woff2-variations');
    font-weight: 100 900;
    font-display: swap;
    font-style: italic;
    font-named-instance: 'Italic';
}

/* Global Styles */
body {
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
